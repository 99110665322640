export const tripFetchAction = (payload) => {
  return {
    type: 'tripFetchAction',
    payload
  };
};

export const tripFetchSuccess = (payload) => {
  return {
    type: 'tripFetchSuccess',
    payload
  };
};

export const tripFetchError = (payload) => {
  return {
    type: 'tripFetchError',
    payload
  };
};
