import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import GridContainer from '../../components/material-ui/Grid/GridContainer';
import Notifications from './show';
import { cardTitle, primaryBoxShadow } from '../../assets/jss/material-dashboard-react';
import flexStyle from '../../assets/jss/material-dashboard-react/components/flexStyle';
import dashboardStyle from '../../assets/jss/material-dashboard-react/views/dashboardStyle';
import contentStyle from '../../assets/jss/material-dashboard-react/components/contentStyle';

const styles = {
  ...flexStyle,
  ...dashboardStyle,
  ...contentStyle,
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  titleGray: {
    color: '#9C9C9C',
    fontSize: '24px'
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: '\'Roboto\', \'Helvetica\', \'Arial\', sans-serif',
    marginBottom: '3px',
    textDecoration: 'none'
  },
  cardBlue: {
    backgroundColor: '#000bac',
    color: '#fff',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0 !important',
    cursor: 'pointer',
    ...primaryBoxShadow
  },
  cardWhite: {
    backgroundColor: '#fff',
    marginTop: '5px',
    marginBottom: '17px',
    fontWeight: '400'
  },
  cardTitle: {
    fontWeight: '500',
    margin: 0,
    marginTop: '5px'
  },
  cardSubTitle: {
    color: '#9C9C9C',
    margin: 0,
  },
  cardTypeContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '13px 0',
    padding: '25px 0 !important',
    '& span.material-icons': {
      fontSize: '3rem',
      marginBottom: '15px'
    },
    '& h2': {
      fontSize: '1.3rem'
    }
  },
  link: {
    cursor: 'pointer'
  },
  errorAlert: {
    width: '1000px',
    margin: '0 auto',
    marginBottom: '50px'
  }
};

const useStyles = makeStyles(styles);

export default function Index() {
  const classes = useStyles();

  return (
    <div>
      <Notifications classes={classes} />
    </div>
  );
}
