import { axios, getConfigs } from './common';

export default class EventService {
  async getList(params) {
    return new Promise((resolve, reject) => {
      let url = `/r2api/event`;

      const options = {};
      const configs = getConfigs('get', 'application/json', url, options);

      configs.params = params;

      axios(configs, resolve, reject);
    });
  }
}
