import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons
// core components

import dashboardStyle from '../../assets/jss/material-dashboard-react/views/dashboardStyle';
import Dashboard from './show';
import contentStyle from '../../assets/jss/material-dashboard-react/components/contentStyle';

const styles = {
  ...contentStyle,
  ...dashboardStyle,
  buttonSlim: {
    paddingTop: '6px',
    paddingBottom: '6px'
  }
};

const useStyles = makeStyles(styles);

export default function Index() {
  const classes = useStyles();

  return (
    <Dashboard classes={classes}/>
  );
}
