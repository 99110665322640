import { axios, getConfigs } from './common';

export default class MediumService {
  async getList(params) {
    return new Promise(async (resolve, reject) => {
      let url = `/mediums`;

      const options = {};
      const configs = getConfigs('get', 'application/json', url, options);

      configs.params = params;

      axios(configs, resolve, reject);
    });
  }
}
