import React from 'react';
import { connect } from 'react-redux';
import EventsTable from '../EventsTable';
import Button from '../../material-ui/CustomButtons/Button';
import Box from '@material-ui/core/Box';
import GridContainer from '../../material-ui/Grid/GridContainer';
import Link from '@material-ui/core/Link';
import { tripFetchAction } from '../../../store/trips/actions';
import moment from 'moment';
import TripsTable from '../TripsTable';
import SelectEvents from './SelectEvents';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

class SelectTrips extends React.Component {
  constructor(props) {
    super(props);

    this.classes = {
      ...props.classes
    };
  }

  getEventDateFilterParams(fromDate, toDate) {
    let from = new Date(fromDate);
    let to = new Date(toDate);
    from.setHours(0, 0, 0);
    to.setHours(23, 59, 59);

    return {
      fromDate: from,
      toDate: to
    };
  }

  componentDidMount() {
    const { dateFrom, dateTo, trips, fetchAction, handleChange } = this.props;

    if (!trips || trips.length === 0) {
      fetchAction({
        params: this.getEventDateFilterParams(dateFrom, dateTo)
      });
    }

    handleChange('events', []);
    handleChange('pushCategories', ['trips']);
  }

  handleToggleTrips = (tripsToggled, selectAll = false) => {
    const { trips, selectedTrips, handleChange } = this.props;
    let newChecked = [...selectedTrips];

    for (const trip of tripsToggled) {
      const currentIndex = selectedTrips.map(x => x.id).indexOf(trip.id);

      if (currentIndex === -1) {
        newChecked.push(trips.find(x => x.id === trip.id));
      } else {
        if (!selectAll) {
          newChecked = newChecked.filter(x => x.id !== trip.id);
        }
      }
    }

    handleChange('trips', newChecked);
  };

  handleDateFromFilter = date => {
    const { fetchAction, handleChange, dateTo } = this.props;
    handleChange('trips', []);
    handleChange('dateFrom', date);

    let _dateTo = dateTo;
    if (moment(date).isAfter(dateTo)) {
      _dateTo = date;
      handleChange('dateTo', date);
    }

    fetchAction({
      params: this.getEventDateFilterParams(date, _dateTo)
    });
  };

  handleDateToFilter = date => {
    const { fetchAction, handleChange, dateFrom } = this.props;

    handleChange('trips', []);
    handleChange('dateTo', date);

    let _dateFrom = dateFrom;
    if (moment(dateFrom).isAfter(date)) {
      _dateFrom = date;
      console.log('dateFrom', dateFrom);
      handleChange('dateFrom', _dateFrom);
    }

    fetchAction({
      params: this.getEventDateFilterParams(_dateFrom, date)
    });
  };

  handleDateFilter = (date, name) => {
    if (name === 'dateFrom') {
      this.handleDateFromFilter(new Date(date));
    } else if (name === 'dateTo') {
      this.handleDateToFilter(new Date(date));
    }
  };

  render() {
    const { classes, trips, prevStep, nextStep, selectedTrips, dateFrom, dateTo, errors } = this.props;

    return (
      <GridContainer className={classes.contentContainer}>
        {errors && errors.length > 0 && (
          <Alert severity="error" className={classes.errorAlert}>
            <AlertTitle>Error</AlertTitle>
            {errors.map((error) =>
              (
                <p>
                  {error['error_description']}
                </p>
              )
            )}
          </Alert>
        )}
        <TripsTable
          trips={trips}
          dateFrom={dateFrom}
          dateTo={dateTo}
          selectedTrips={selectedTrips}
          classes={classes}
          handleToggle={this.handleToggleTrips}
          handleDateFilter={this.handleDateFilter}
        />

        <Box display="flex" justifyContent="flex-end" alignItems="center" width="100%">
          <Link className={classes.link} onClick={prevStep} style={{ marginRight: '20px' }}>Abbrechen</Link>
          {selectedTrips.length > 0 &&
            <Button color="primary" onClick={nextStep}>
              Auswahl übernehmen
          </Button>
          }
        </Box>
      </GridContainer>
    );
  }
}

const mapStateToProps = ({ trips }) => ({
  trips: trips.data,
  loading: trips.loading,
  errors: trips.errors
});


const mapDispatchToProps = dispatch => ({
  fetchAction: (payload) => dispatch(tripFetchAction(payload))
});


export default connect(mapStateToProps, mapDispatchToProps)(SelectTrips);
