import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import LayoutRoutes from '../routes';

export default function App(props) {
  const { store, history } = props;

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <LayoutRoutes history={history}/>
      </ConnectedRouter>
    </Provider>
  );
}