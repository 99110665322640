export const initialState = {
  data: [],
  errors: undefined,
  loading: false
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case 'messageTemplateFetchAction': {
      return { ...state, loading: true };
    }
    case 'messageTemplateFetchSuccess': {
      return { ...state, loading: false, data: action.payload };
    }
    case 'messageTemplateFetchError': {
      return { ...state, loading: true, errors: action.payload };
    }
    default: {
      return state;
    }
  }
}

export { reducer as messageTemplateReducer }