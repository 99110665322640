import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from 'contexts/authContext';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { user, loading } = useAuth();
  /* ToDo: loader */
  if (loading) return null;
  return (
    <Route
      render={props =>
        user ? <Component {...props} /> : <Redirect to="/login" />
      }
      {...rest}
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.any
};

export default ProtectedRoute;
