export const eventFetchAction = (payload) => {
  return {
    type: 'eventFetchAction',
    payload
  };
};

export const eventFetchSuccess = (payload) => {
  return {
    type: 'eventFetchSuccess',
    payload
  };
};

export const eventFetchError = (payload) => {
  return {
    type: 'eventFetchError',
    payload
  };
};