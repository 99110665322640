import * as React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import AuthProvider from '../contexts/authContext';
import ProtectedRoute from '../ProtectedRoute';
import Admin from '../layouts/Admin';
import Login from '../layouts/Login';

export default function LayoutRoutes(props) {
  const { history } = props;

  return (<Router history={history}>
    <AuthProvider>
      <Switch>
        <ProtectedRoute path="/admin" component={Admin}/>
        <Route path="/login" component={Login}/>
        <Redirect from="/" to="/admin/dashboard"/>
      </Switch>
    </AuthProvider>
  </Router>);
}