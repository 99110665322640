import React from 'react';
import { connect } from 'react-redux';
import { eventFetchAction } from '../../../store/events/actions';
import EventsTable from '../EventsTable';
import Button from '../../material-ui/CustomButtons/Button';
import Box from '@material-ui/core/Box';
import GridContainer from '../../material-ui/Grid/GridContainer';
import Link from '@material-ui/core/Link';
import moment from 'moment';
import EventService from '../../../services/events';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

const sortByDate = events => {
  return events.sort(
    (a, b) =>
      new Date(a.eventDates[0].date).getTime() -
      new Date(b.eventDates[0].date).getTime()
  );
};

const service = new EventService();

class SelectEvents extends React.Component {
  constructor(props) {
    super(props);

    this.classes = {
      ...props.classes
    };
  }

  getEventDateFilterParams(fromDate, toDate) {
    const fromDateStr = moment(fromDate).format('YYYY-MM-DD');
    const toDateStr = moment(toDate).format('YYYY-MM-DD');

    return {
      from: fromDateStr,
      to: toDateStr
    };
  }

  async componentDidMount() {
    const { events, fetchAction, handleChange, dateFrom, dateTo } = this.props;

    if (!events || events.length === 0) {
      fetchAction({
        params: this.getEventDateFilterParams(dateFrom, dateTo)
      });
    }

    handleChange('trips', []);
    handleChange('pushCategories', ['events']);
  }

  handleToggleEvents = (eventsToggled, selectAll = false) => {
    const { events, selectedEvents, handleChange } = this.props;
    let newChecked = [...selectedEvents];

    for (const event of eventsToggled) {
      const currentIndex = selectedEvents.map(x => x.id).indexOf(event.id);

      if (currentIndex === -1) {
        newChecked.push(events.find(x => x.id === event.id));
      } else {
        if (!selectAll) {
          newChecked = newChecked.filter(x => x.id !== event.id);
        }
      }
    }

    handleChange('events', newChecked);
  };

  handleDateFromFilter = async (date) => {
    const { handleChange, fetchAction, dateTo } = this.props;

    handleChange('events', []);
    handleChange('dateFrom', date);

    let _dateTo = dateTo;
    if (moment(date).isAfter(dateTo)) {
      _dateTo = date;
      handleChange('dateTo', date);
    }

    fetchAction({
      params: this.getEventDateFilterParams(date, _dateTo)
    });
  };

  handleDateToFilter = async (date) => {
    const { handleChange, fetchAction, dateFrom } = this.props;

    handleChange('events', []);
    handleChange('dateTo', date);

    let _dateFrom = dateFrom;
    if (moment(dateFrom).isAfter(date)) {
      _dateFrom = date;
      handleChange('dateFrom', date);
    }

    fetchAction({
      params: this.getEventDateFilterParams(_dateFrom, date)
    });
  };

  handleDateFilter = (date, name) => {
    if (name === 'dateFrom') {
      this.handleDateFromFilter(new Date(date));
    } else if (name === 'dateTo') {
      this.handleDateToFilter(new Date(date));
    }
  };

  render() {
    const { classes, events, prevStep, nextStep, selectedEvents, dateFrom, dateTo, errors } = this.props;

    return (
      <GridContainer className={classes.contentContainer}>
        {errors && errors.length > 0 && (
          <Alert severity="error" className={classes.errorAlert}>
            <AlertTitle>Error</AlertTitle>
            {errors.map((error) =>
            (
              <p>
                {error['error_description']}
              </p>
            )
            )}
          </Alert>
        )}
        <EventsTable
          events={sortByDate(events)}
          selectedEvents={selectedEvents}
          classes={classes}
          dateFrom={dateFrom}
          dateTo={dateTo}
          handleToggle={this.handleToggleEvents}
          handleDateFilter={this.handleDateFilter} />

        <Box display="flex" justifyContent="flex-end" alignItems="center" width="100%">
          <Link className={classes.link} onClick={prevStep} style={{ marginRight: '20px' }}>Abbrechen</Link>
          {selectedEvents.length > 0 &&
            <Button color="primary" onClick={nextStep}>
              Auswahl übernehmen
          </Button>
          }
        </Box>
      </GridContainer>
    );
  }
}

const mapStateToProps = ({ events }) => ({
  events: events.data,
  loading: events.loading,
  errors: events.errors
});


const mapDispatchToProps = dispatch => ({
  fetchAction: (payload) => dispatch(eventFetchAction(payload))
});


export default connect(mapStateToProps, mapDispatchToProps)(SelectEvents);
