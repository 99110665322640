import React, { useEffect, useState } from 'react';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/de';
import TableBody from '@material-ui/core/TableBody';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

import CardHeader from '../material-ui/Card/CardHeader';
import CardBody from '../material-ui/Card/CardBody';
import Card from '../material-ui/Card/Card';
import GridItem from '../material-ui/Grid/GridItem';
import GridContainer from '../material-ui/Grid/GridContainer';

import styles from '../../assets/jss/material-dashboard-react/components/tasksStyle';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

const eventTableStyles = {
  ...styles
};

const useStyles = makeStyles(eventTableStyles);

export default function EventsTable(props) {
  const { events, selectedEvents, handleToggle, handleDateFilter, dateFrom, dateTo } = props;
  const classes = useStyles();
  const tableHead = ['Anfang', 'Titel', 'Kategorie', 'Veranstalter'];
  const [loading, setLoading] = useState(true);

  const selectedAll = events.map(x => x.id)
    .filter(eventId => !selectedEvents.map(x => x.id)
      .includes(eventId)).length === 0;

  const selectAllEvents = () => {
    handleToggle(events, !selectedAll);
  };

  const handleDateFromChange = async (date) => {
    setLoading(true);
    handleDateFilter(date, 'dateFrom');
  };

  const handleDateToChange = async (date) => {
    setLoading(true);
    handleDateFilter(date, 'dateTo');
  };

  useEffect(() => {
    setLoading(!(!!events && events.length > 0));
  }, [events]);

  return (
    <Card>
      <CardHeader color="primary">
        <GridContainer>
          <GridItem xs={6} sm={6} md={6}>
            <h4 className={classes.cardTitleWhite}>Veranstaltungen auswählen</h4>
            <p className={classes.cardCategoryWhite}>
              Bitte wählen Sie aus den unteren Veranstaltungen aus
            </p>
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <Box className={classes.displayFlex + ' ' + classes.contentEnd + ' ' + classes.alignCenter}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                <Grid container justify="flex-end">
                  <DatePicker
                    disableToolbar
                    style={{ width: '100px' }}
                    label="Zeitraum: "
                    className={classes.datePickerWhite + ' ' + classes.contentEnd + ' ' + classes.alignCenter}
                    variant="inline"
                    format="dd.MM.yyyy"
                    value={dateFrom}
                    onChange={handleDateFromChange}
                  />
                  <DatePicker
                    disableToolbar
                    style={{ width: '100px' }}
                    // label="Bis:"
                    className={classes.datePickerWhite + ' ' + classes.contentEnd + ' ' + classes.alignCenter}
                    variant="inline"
                    format="dd.MM.yyyy"
                    value={dateTo}
                    onChange={handleDateToChange}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Box>
          </GridItem>
        </GridContainer>
      </CardHeader>
      <CardBody>
        {loading && <div className={classes.loading}>Loading...</div>}
        <Table className={classes.table}>
          <TableHead className={classes.primaryTableHeader}>
            <TableRow className={classes.tableHeadRow}>
              <TableCell className={classes.tableCell + ' ' + classes.tableHeadCell}
                style={{ maxWidth: '10px' }}>
                <Checkbox
                  tabIndex={-1}
                  onClick={() => selectAllEvents()}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  checked={selectedAll}
                  classes={{
                    checked: classes.checked,
                    root: classes.root
                  }}
                />
              </TableCell>
              {tableHead.map((colName, key) => (
                <TableCell className={classes.tableCell + ' ' + classes.tableHeadCell} key={key}>
                  {colName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {events.map(event => (
              <TableRow key={event.id} className={classes.tableRow}>
                <TableCell className={classes.tableCell}
                  style={{ maxWidth: '60px' }}>
                  <Checkbox
                    tabIndex={-1}
                    onClick={() => handleToggle([event])}
                    checked={selectedEvents.map(x => x.id).includes(event.id)}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.root
                    }}
                  />
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {event.eventDates && event.eventDates.length > 0 && (
                    <>{moment(event.eventDates[0].date).format('DD.MM.YY, HH:mm')} Uhr</>
                  )}
                </TableCell>
                <TableCell className={classes.tableCell}>{event.title}</TableCell>
                <TableCell
                  className={classes.tableCell}>{event.category?.title}</TableCell>
                <TableCell className={classes.tableCell}>{event.organizer?.title}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardBody>
    </Card>
  );
}
