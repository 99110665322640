/*eslint-disable*/
import React from 'react';
import { mediumTypeFetchAction, mediumTypeSelectAction } from '../../store/medium_types/actions';
import { messageFetchAction } from '../../store/messages/actions';
import { connect } from 'react-redux';
import GridContainer from '../../components/material-ui/Grid/GridContainer';
import GridItem from '../../components/material-ui/Grid/GridItem';
import MediumMessageTable from '../../components/dashboard/MediumMessageTable';
import Box from '@material-ui/core/Box';
import Button from '../../components/material-ui/CustomButtons/Button';
import { sms } from '../../models/mediumTypeEnum';

class Archive extends React.Component {
  async componentDidMount() {
    const { mediumTypesFetchAction, mediumTypes } = this.props;

    await mediumTypesFetchAction();

    this.handleMediumTypeChange(sms);
  }

  handleMediumTypeChange = (mediumTypeId) => {
    const { messagesFetchAction, messageLimit, mediumTypeSelectAction } = this.props;

    mediumTypeSelectAction(mediumTypeId);

    messagesFetchAction({
      params: {
        medium: mediumTypeId,
        _limit: messageLimit
      }
    });
  };

  loadMessages = () => {
    const { messagesFetchAction, mediumType, messageLimit, messagePage } = this.props;

    messagesFetchAction({
      params: {
        medium: mediumType,
        _start: messageLimit * messagePage,
        _limit: messageLimit
      }
    });
  };

  render() {
    const { messages, messagePage, messageLimit, mediumType, classes } = this.props;

    return (
      <GridContainer className={classes.contentContainer}>
        <GridItem xs={12} sm={12} md={12}>
          <MediumMessageTable classes={classes}
            footer={messages.filter(x => x.medium && x.medium.id === mediumType).length >= messageLimit * messagePage &&
              <Box display="flex" justifyContent="center" width="100%">
                <Button color="primary" onClick={this.loadMessages}>
                  Mehr laden
                </Button>
              </Box>
            } rowsLimit={messageLimit * messagePage} {...this.props}
            handleMediumTypeChange={this.handleMediumTypeChange} />
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = ({ mediumTypes, messages }) => ({
  mediumTypes: mediumTypes.data,
  mediumType: mediumTypes.currentId,

  messages: messages.data,
  loading: messages.loading,
  messageLimit: messages.limitOnPage,
  messagePage: messages.page,
  errors: messages.errors
});


const mapDispatchToProps = dispatch => ({
  mediumTypesFetchAction: async (payload) => await dispatch(mediumTypeFetchAction(payload)),
  messagesFetchAction: async (payload) => await dispatch(messageFetchAction(payload)),
  mediumTypeSelectAction: async (id) => await dispatch(mediumTypeSelectAction(id))
});


export default connect(mapStateToProps, mapDispatchToProps)(Archive);
