import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import configureStore from './store/config';

import App from 'views/App';

import 'assets/css/material-dashboard-react.css?v=1.8.0';
import ProtectedRoute from 'ProtectedRoute';
import AuthProvider from 'contexts/authContext';

const initialState = window.INITIAL_REDUX_STATE;

const history = createBrowserHistory();
const store = configureStore(history, initialState);

ReactDOM.render(
  <App store={store} history={history}/>,
  document.getElementById('root')
);
