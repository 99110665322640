/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import styles from "../../../assets/jss/material-dashboard-react/components/footerStyle.js";
import upcommerceLogo from "./upcommerce_logo.svg";
import mdsiLogo from "./mdsi_logo.png";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <div className={classes.footerContent}>
          <p>
            Powered by
            <a href="https://upcommerce.de/" className={classes.logoLink}>
              <img src={upcommerceLogo} alt="upcommerce" className={classes.logo} />
            </a>
            und
            <a href="https://www.mdsi.de/" className={classes.logoLink}>
              <img src={mdsiLogo} alt="MDSI It Solutions" className={classes.logo} />
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}
