import React, { useState, useEffect, useContext, createContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { API_URL } from 'configs/server';

export const AuthContext = createContext();

const { Provider } = AuthContext;

const AuthProvider = ({ children }) => {
  const auth = useProviderAuth();
  return <Provider value={auth}>{children}</Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

function useProviderAuth() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    const localUser = sessionStorage.getItem('account');
    if (localUser) setUser(JSON.parse(localUser));
    setLoading(false);
  }, []);

  const login = async (email, password) => {
    return await axios
      .post(API_URL + '/auth/local', {
        identifier: email,
        password: password
      })
      .then(response => {
        if (response.data) {
          const account = {
            token: response.data.jwt,
            id: response.data.user.id,
            username: response.data.user.username,
            email: response.data.user.email,
            role: response.data.user.role.type
          };

          setUser(account);
          sessionStorage.setItem('account', JSON.stringify(account));
        }
      })
      .catch(error => {
        if (error.response && error.response.data.message) {
          setErrors(error.response.data.message.flatMap(m => m.messages).map(m => m.message));
        }
      });
  };

  const logout = () => {
    setUser(null);
    sessionStorage.removeItem('account');
  };

  return {
    user,
    loading,
    errors,
    login,
    logout
  };
}

AuthProvider.propTypes = {
  children: PropTypes.node
};

export default AuthProvider;
