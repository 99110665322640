import { connect } from 'react-redux';
import { messageFetchAction } from '../../store/messages/actions';
import GridItem from '../material-ui/Grid/GridItem';
import DashboardCard from './DashboardCard';
import GridContainer from '../material-ui/Grid/GridContainer';
import React from 'react';
import { mediumTypeFetchAction } from '../../store/medium_types/actions';
import Icon from '@material-ui/core/Icon';
import { Box } from '@material-ui/core';
import recipients from '../../services/recipients';


class DashboardMediumTypesStats extends React.Component {
  componentDidMount() {
  }

  render() {
    const { messages, mediumTypes, loading, classes } = this.props;


    return (
      <GridContainer>
        {loading && messages.length === 0 && (
          <span>Loading...</span>
        )}

        {mediumTypes.filter(mediumType => mediumType.useInStats)
          .map((mediumType) => {
            return (
              <GridItem xs={12} sm={6} md={4} key={mediumType.id}>
                <DashboardCard color={mediumType.color} icon={mediumType.Icon} name={mediumType.name}
                               value={messages.filter(x => x.medium && x.medium.id === mediumType.id).reduce((sum, message) => sum + message.Receivers, 0)}
                               footer={<Box><Icon>calendar_today</Icon> In den letzen 14 Tagen</Box>} classes={classes}>
                </DashboardCard>
              </GridItem>
            );
          })}
      </GridContainer>
    );
  }
}

const mapStateToProps = ({ mediumTypes, messages }) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardMediumTypesStats);
