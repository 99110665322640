import {all, fork, put, takeEvery} from "redux-saga/effects";
import { notificationTypeFetchError, notificationTypeFetchSuccess } from './actions';
import NotificationTypeService from '../../services/notificationTypes';

const service = new NotificationTypeService();

function* handleFetch(payload) {
  try {
    const data = yield service.getList(payload.payload);

    yield put(notificationTypeFetchSuccess(data))
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(notificationTypeFetchError(err.stack))
    } else {
      yield put(notificationTypeFetchError('An unknown error occured.'))
    }
  }
}

function* watchFetchRequest() {
  yield takeEvery('notificationTypeFetchAction', handleFetch)
}

function* typeSaga() {
  yield all([fork(watchFetchRequest)])
}

export default typeSaga;