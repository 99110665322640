import Dashboard from '@material-ui/icons/Dashboard';
import DashboardPage from '../views/Dashboard';
import Message from '@material-ui/icons/Message';
import NotificationsPage from '../views/Notifications';
import Archive from '@material-ui/icons/Archive';
import ArchivePage from '../views/Archive';
import Subject from '@material-ui/icons/Subject';
import TemplatesPage from '../views/Templates/Templates';

const adminRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: Dashboard,
    component: DashboardPage,
    layout: '/admin'
  },
  {
    path: '/notifications',
    name: 'Benachrichtungen',
    icon: Message,
    meta: {
      showTitle: false
    },
    component: NotificationsPage,
    layout: '/admin'
  },
  {
    path: '/archive',
    name: 'Archive',
    icon: Archive,
    component: ArchivePage,
    meta: {
      showTitle: false
    },
    layout: '/admin'
  },
  // {
  //   path: '/templates',
  //   name: 'Vorlagen',
  //   icon: Subject,
  //   component: TemplatesPage,
  //   layout: '/admin'
  // }
];

export default adminRoutes;
