import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { notificationTypeFetchAction } from '../../../store/notification_types/actions';
import GridContainer from '../../material-ui/Grid/GridContainer';
import GridItem from '../../material-ui/Grid/GridItem';
import { Box } from '@material-ui/core';
import Card from '../../material-ui/Card/Card';
import Icon from '@material-ui/core/Icon';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

class SelectNotifyType extends React.Component {
  componentDidMount() {
    const { types, notificationTypesFetchAction, handleChange } = this.props;

    if (!types || types.length === 0)
      notificationTypesFetchAction();
  }

  render() {
    const { types, classes, handleChange, nextStep } = this.props;

    return (
      <GridContainer className={classes.contentContainer} style={{marginTop: '70px'}}>
        <GridItem xs={12} lg={12}>
          <Box display="flex" justifyContent="center">
            <h4 className={classes.titleGray}>Bitte wählen Sie die gewünschten Versandmethoden aus</h4>
          </Box>
        </GridItem>
        <GridItem xs={12} lg={12}>
          <GridContainer>
            {types.map(type => (
              <GridItem xs={4} lg={4} key={type.id}>
                <Card className={classes.cardBlue} onClick={async () => {
                  await handleChange('notifyTypeId', type.id);

                  nextStep();
                }}>
                  <CardContent className={classes.cardTypeContent}>
                    <Icon>{type.icon}</Icon>
                    <Typography gutterBottom variant="h5" component="h2">
                      {type.name}
                    </Typography>
                  </CardContent>
                </Card>
              </GridItem>
            ))}
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = ({ types }) => ({
  types: types.data,
  loading: types.loading,
  errors: types.errors
});


const mapDispatchToProps = dispatch => ({
  notificationTypesFetchAction: (payload) => dispatch(notificationTypeFetchAction(payload))
});


export default connect(mapStateToProps, mapDispatchToProps)(SelectNotifyType);
