export const mediumTypeFetchAction = (payload) => {
  return {
    type: 'mediumTypeFetchAction',
    payload
  };
};

export const mediumTypeSelectAction = (payload) => {
  return {
    type: 'mediumTypeSelectAction',
    payload
  };
};

export const mediumTypeFetchSuccess = (payload) => {
  return {
    type: 'mediumTypeFetchSuccess',
    payload
  };
};

export const mediumTypeFetchError = (payload) => {
  return {
    type: 'mediumTypeFetchError',
    payload
  };
};
