const flexStyle = {
  displayFlex: {
    display: 'flex'
  },
  displayInlineFlex: {
    display: 'inline-flex'
  },
  contentCenter: {
    justifyContent: 'center'
  },
  contentStart: {
    justifyContent: 'flex-start'
  },
  contentEnd: {
    justifyContent: 'flex-end'
  },
  alignCenter: {
    alignItems: 'center'
  }
};

export default flexStyle;