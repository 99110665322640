import React from 'react';
import { connect } from 'react-redux';

import DashboardMediumTypesStats from '../../components/dashboard/DashboardMediumTypesStats';
import GridContainer from '../../components/material-ui/Grid/GridContainer';
import GridItem from '../../components/material-ui/Grid/GridItem';
import MediumMessageTable from '../../components/dashboard/MediumMessageTable';
import { mediumTypeFetchAction } from '../../store/medium_types/actions';
import { messageFetchAction } from '../../store/messages/actions';
import Button from '../../components/material-ui/CustomButtons/Button';
import { NavLink } from 'react-router-dom';
import Box from '@material-ui/core/Box';

class Dashboard extends React.Component {
  componentDidMount() {
    const { mediumTypesFetchAction, messagesFetchAction } = this.props;

    const createdDateStart = new Date();
    createdDateStart.setDate(createdDateStart.getDate() - 14);

    messagesFetchAction({
      params: {
        created_at_gte: createdDateStart,
        created_at_lte: new Date()
      }
    });

    mediumTypesFetchAction();
  }

  render() {
    const { classes, messageLimit } = this.props;

    return (
      <GridContainer className={classes.contentContainer}>
        <GridItem xs={12} sm={12} md={12}>
          <DashboardMediumTypesStats classes={classes} {...this.props}/>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <MediumMessageTable classes={classes} footer={
                <Box display="flex" justifyContent="center" width="100%">
                  <NavLink to={'/admin/archive'}>
                    <Button color="primary">
                      Zum Archiv
                    </Button>
                  </NavLink>
                </Box>
              } rowsLimit={messageLimit} {...this.props}/>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = ({ mediumTypes, messages }) => ({
  mediumTypes: mediumTypes.data,

  messages: messages.data,
  messageLimit: messages.limitOnPage,
  loading: messages.loading,
  errors: messages.errors
});


const mapDispatchToProps = dispatch => ({
  mediumTypesFetchAction: (payload) => dispatch(mediumTypeFetchAction(payload)),
  messagesFetchAction: (payload) => dispatch(messageFetchAction(payload))
});


export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
