import React from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// material-ui components
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// core components
import Card from '../../../components/material-ui/Card/Card';
import CardBody from '../../../components/material-ui/Card/CardBody';
import CardHeader from '../../../components/material-ui/Card/CardHeader';
import GridItem from '../../../components/material-ui/Grid/GridItem';
import GridContainer from '../../../components/material-ui/Grid/GridContainer';

import customTabsStyle from '../../../assets/jss/material-dashboard-react/components/customTabsStyle';
import CardFooter from '../Card/CardFooter';
import Icon from '@material-ui/core/Icon';

const styles = {
  ...customTabsStyle,
  tableResponsive: {
    marginTop: 0
  }
};

const useStyles = makeStyles(styles);

export default function CustomTabs(props) {
  const { headerColor, plainTabs, tabs, title, subtitle, footer, onChange } = props;
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    if (onChange && tabs.length > 0) onChange(tabs[value].tabKey);
  }, [value]);

  const handleChange = (event, value) => {
    setValue(value);
  };

  const classes = useStyles();

  return (
    <Card plain={plainTabs}>
      <CardHeader color={headerColor} plain={plainTabs}>
        <GridContainer>
          <GridItem xs={6} sm={6} md={6}>
            {title !== undefined ? (
              <h4 className={classes.cardTitle}>{title}</h4>
            ) : null}
            {subtitle !== undefined ? (
              <p className={classes.cardSubTitle}>{subtitle}</p>
            ) : null}
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <Tabs
              value={value}
              onChange={handleChange}
              classes={{
                root: classes.tabsRoot,
                indicator: classes.displayNone,
                scrollButtons: classes.displayNone
              }}
              variant="scrollable"
              scrollButtons="auto"
            >
              {tabs.map((prop, key) => {
                return (
                  <Tab
                    classes={{
                      root: classes.tabRootButton,
                      selected: classes.tabSelected,
                      wrapper: classes.tabWrapper
                    }}
                    key={prop.tabKey}
                    label={prop.tabName}
                    icon={(prop.tabMeta && prop.tabMeta.icon ? <Icon>{prop.tabMeta.icon}</Icon> : '')}
                  />
                );
              })}
            </Tabs>
          </GridItem>
        </GridContainer>
      </CardHeader>
      <CardBody>
        {tabs.map((prop, key) => {
          if (key === value) {
            return <div key={key}>{prop.tabContent}</div>;
          }
          return null;
        })}
      </CardBody>
      <CardFooter>
        {footer}
      </CardFooter>
    </Card>
  );
}

CustomTabs.propTypes = {
  headerColor: PropTypes.oneOf([
    'warning',
    'success',
    'danger',
    'info',
    'primary',
    'rose'
  ]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabName: PropTypes.string.isRequired,
      tabIcon: PropTypes.object,
      tabContent: PropTypes.node.isRequired
    })
  ),
  plainTabs: PropTypes.bool
};
