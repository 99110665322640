import { all, fork, put, takeEvery } from 'redux-saga/effects';
import {
  messageFetchAction,
  messageActionError,
  messageFetchSuccess,
  messageCreateSuccess,
  messageDeleteSuccess, messageFetchPagedSuccess
} from './actions';
import MessageService from '../../services/messages';

const service = new MessageService();

function* handleFetch(action) {
  try {
    const data = yield service.getMessages(action.payload.params);

    if (!action.payload.params._start || action.payload.params._start === 0) {
      yield put(messageFetchSuccess(data));
    } else {
      yield put(messageFetchPagedSuccess(data));
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(messageActionError(err.stack));
    } else {
      yield put(messageActionError('An unknown error occured.'));
    }
  }
}

function* handleCreate(action) {
  try {
    const created = yield service.create(action.payload);

    yield put(messageCreateSuccess(created));
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(messageActionError(err.stack));
    } else {
      yield put(messageActionError('An unknown error occured.'));
    }
  }
}

function* handleDelete(action) {
  try {
    const deleted = yield service.delete(action.payload.id);

    yield put(messageDeleteSuccess(deleted));
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(messageActionError(err.stack));
    } else {
      yield put(messageActionError('An unknown error occured.'));
    }
  }
}

function* watchFetchRequest() {
  yield takeEvery('messageFetchAction', handleFetch);
}

function* watchCreateRequest() {
  yield takeEvery('messageCreateAction', handleCreate);
}

function* watchDeleteRequest() {
  yield takeEvery('messageDeleteAction', handleDelete);
}

function* messagesSaga() {
  yield all([fork(watchFetchRequest), fork(watchCreateRequest), fork(watchDeleteRequest)]);
}

export default messagesSaga;
