export const notificationTypeFetchAction = (payload, json) => {
  return {
    type: 'notificationTypeFetchAction',
    payload,
    types: json
  };
};

export const notificationTypeFetchSuccess = (payload) => {
  return {
    type: 'notificationTypeFetchSuccess',
    payload
  };
};

export const notificationTypeFetchError = (payload) => {
  return {
    type: 'notificationTypeFetchError',
    payload
  };
};