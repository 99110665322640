import React, { useEffect, useState } from 'react';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/de';
import TableBody from '@material-ui/core/TableBody';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

import CardHeader from '../material-ui/Card/CardHeader';
import CardBody from '../material-ui/Card/CardBody';
import Card from '../material-ui/Card/Card';
import GridItem from '../material-ui/Grid/GridItem';
import GridContainer from '../material-ui/Grid/GridContainer';

import styles from '../../assets/jss/material-dashboard-react/components/tasksStyle';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

const tripTableStyles = {
  ...styles
};

const useStyles = makeStyles(tripTableStyles);

export default function TripsTable(props) {
  const { trips, selectedTrips, handleToggle, handleDateFilter, dateFrom, dateTo } = props;
  const classes = useStyles();
  const tableHead = ['Abfahrt', 'Richtung', 'Schiff'];
  const [loading, setLoading] = useState(true);

  const selectedAll = trips.map(x => x.id)
    .filter(tripId => !selectedTrips.map(x => x.id)
      .includes(tripId)).length === 0;

  const selectAllTrips = () => {
    handleToggle(trips, !selectedAll);
  };

  const handleDateFromChange = async (date) => {
    setLoading(true);
    handleDateFilter(date, 'dateFrom');
  };

  const handleDateToChange = async (date) => {
    setLoading(true);
    handleDateFilter(date, 'dateTo');
  };

  useEffect(() => {
    setLoading(!(!!trips && trips.length > 0));
  }, [trips]);

  return (
    <Card>
      <CardHeader color="primary">
        <GridContainer>
          <GridItem xs={6} sm={6} md={6}>
            <h4 className={classes.cardTitleWhite}>Fahrt auswählen</h4>
            <p className={classes.cardCategoryWhite}>
              Bitte wählen Sie aus den unteren Fahrten aus
            </p>
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <Box className={classes.displayFlex + ' ' + classes.contentEnd + ' ' + classes.alignCenter}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                <Grid container justify="flex-end">
                  <DatePicker
                    disableToolbar
                    style={{ width: '100px' }}
                    label="Zeitraum:"
                    className={classes.datePickerWhite + ' ' + classes.contentEnd + ' ' + classes.alignCenter}
                    variant="inline"
                    format="dd.MM.yyyy"
                    value={dateFrom}
                    onChange={handleDateFromChange}
                  />
                  <DatePicker
                    disableToolbar
                    style={{ width: '100px' }}
                    // label="Bis:"
                    className={classes.datePickerWhite + ' ' + classes.contentEnd + ' ' + classes.alignCenter}
                    variant="inline"
                    format="dd.MM.yyyy"
                    value={dateTo}
                    onChange={handleDateToChange}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Box>
          </GridItem>
        </GridContainer>
      </CardHeader>
      <CardBody>
        {loading && <div className={classes.loading}>Loading...</div>}
        <Table className={classes.table}>
          <TableHead className={classes.primaryTableHeader}>
            <TableRow className={classes.tableHeadRow}>
              <TableCell className={classes.tableCell + ' ' + classes.tableHeadCell}
                style={{ maxWidth: '10px' }}>
                <Checkbox
                  tabIndex={-1}
                  onClick={() => selectAllTrips()}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  checked={selectedAll}
                  classes={{
                    checked: classes.checked,
                    root: classes.root
                  }}
                />
              </TableCell>
              {tableHead.map((colName, key) => (
                <TableCell className={classes.tableCell + ' ' + classes.tableHeadCell} key={key}>
                  {colName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {trips.map(trip => (
              <TableRow key={trip.id} className={classes.tableRow}>
                <TableCell className={classes.tableCell}
                  style={{ maxWidth: '10px' }}>
                  <Checkbox
                    tabIndex={-1}
                    onClick={() => handleToggle([trip])}
                    checked={selectedTrips.map(x => x.id).includes(trip.id)}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.root
                    }}
                  />
                </TableCell>
                <TableCell className={classes.tableCell}>{moment(trip.departure).format('DD.MM.YY, HH:mm')} Uhr</TableCell>
                <TableCell
                  className={classes.tableCell}>{trip.departurePort.name} > {trip.arrivalPort.name}</TableCell>
                <TableCell className={classes.tableCell}>{trip.craft.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardBody>
    </Card>
  );
}
