import React from 'react';

import { connect } from 'react-redux';
import { messageTemplateFetchAction } from '../../../store/message_templates/actions';
import GridContainer from '../../material-ui/Grid/GridContainer';
import MediumTypeMessageTemplateCard from '../MediumTypeMessageTemplateCard';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Button from '../../material-ui/CustomButtons/Button';

class FillMessages extends React.Component {
  componentDidMount() {
    const { selectedMediumTypes, messageTemplatesFetchAction, notifyType } = this.props;

    messageTemplatesFetchAction({
      params: {
        medium_in: selectedMediumTypes,
        type: notifyType
      }
    });
  }

  componentWillReceiveProps(props, nextProps) {
    const newSelectedTypes = props.selectedMediumTypes.filter(type =>
      !props.messages.map(message => message.mediumType).includes(type));

    if (props.messageTemplates.length > 0 && props.messages.length === 0) {
      const messages = [];

      props.selectedMediumTypes.forEach(async type => {
        const templates = props.messageTemplates.filter(x => x.medium.id === type);

        if (templates.length > 0)
          messages.push(this.buildMessageFromTemplate(templates[0]));
      });

      props.handleChange('messages', messages);
    } else if (newSelectedTypes.length > 0) {
      const messages = props.messages;

      newSelectedTypes.forEach(type => {
        const templates = props.messageTemplates.filter(x => x.medium.id === type);
        if (templates.length > 0)
          messages.push(this.buildMessageFromTemplate(templates[0]));
      });

      props.handleChange('messages', messages);
    }
  }

  //needs to sync adding while templates generate messages
  handleMessage = async (updatedMessage) => {
    return new Promise(async resolve => {
      if (!updatedMessage) resolve();

      const { messages, handleChange } = this.props;

      const existedMessages = [...messages];

      const message = existedMessages.find(x => x.mediumType === updatedMessage.mediumType);

      if (message) {
        message.title = updatedMessage.title;
        message.text = updatedMessage.text;
      } else {
        existedMessages.push(updatedMessage);
      }

      await handleChange('messages', existedMessages);

      resolve();
    });
  };

  buildMessageFromTemplate = (template) => {
    const { selectedEvents, selectedTrips, dateFrom, dateTo } = this.props;

    const templateMessage = {
      title: template.Header,
      text: template.Content,
      mediumType: template.medium.id
    };

    const dateString = moment(dateFrom).isSame(dateTo)
      ? `am ${moment(dateFrom).format('DD.MM.YYYY')}`
      : `${moment(dateFrom).format('DD.MM.YYYY')} - ${moment(dateTo).format('DD.MM.YYYY')}`;

    if (templateMessage.title) {
      templateMessage.title = templateMessage.title.replace('[date]', dateString);
    }

    if (templateMessage.text) {
      templateMessage.text = templateMessage.text.replace('[date]', dateString);
    }

    if (selectedEvents.length > 0) {
      const eventStrings = [];

      selectedEvents.forEach(event => {
        eventStrings.push(`${event.title}`);
      });

      templateMessage.text = templateMessage.text.replace('[events]',
        `${eventStrings.join(', ')}`);
    }

    if (selectedTrips.length > 0) {
      const tripStrings = [];

      const groupBy = (xs, f) => {
        return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
      };

      const groupedTripItems = groupBy(selectedTrips.map(trip => ({
        name: `${trip.departurePort.name} > ${trip.arrivalPort.name}`,
        time: moment(trip.departure).format('HH:mm')
      })), tr => tr.name);

      Object.keys(groupedTripItems).forEach(tripName => {
        tripStrings.push(`${tripName} um ${groupedTripItems[tripName].map(x => x.time).join(', ')}`);
      });

      templateMessage.text = templateMessage.text.replace('[trips]',
        `${tripStrings.join('; ')}`);
    }

    return templateMessage;
  };

  handleTemplateChange = async (template) => {
    return new Promise((resolve => {
      const updatedMessage = this.buildMessageFromTemplate(template);

      this.setState({
        message: updatedMessage
      }, async () => {
        await this.handleMessage(updatedMessage);

        resolve();
      });
    }));
  };

  render() {
    const { classes, messageTemplates, selectedMediumTypes, messages, prevStep, nextStep } = this.props;

    return (
      <GridContainer className={classes.contentContainer} style={{ maxWidth: '700px' }}>
        {selectedMediumTypes.map(mediumTypeId => {
          const templates = messageTemplates.filter(x => x.medium.id === mediumTypeId);
          const message = messages.find(x => x.mediumType === mediumTypeId);

          return (templates.length > 0 && message &&
            <MediumTypeMessageTemplateCard classes={classes}
              key={mediumTypeId}
              mediumType={mediumTypeId}
              templates={templates}
              templateOnChange={this.handleTemplateChange}
              handleMessage={this.handleMessage}
              message={message} />
          );
        })}

        <Box display="flex" justifyContent="flex-end" alignItems="center" width="100%">
          <Link className={classes.link} onClick={prevStep} style={{ marginRight: '20px' }}>Zurück</Link>
          {messages.length > 0 &&
            <Button color="primary" onClick={nextStep} style={{ marginRight: '15px' }}>
              Weiter
          </Button>
          }
        </Box>
      </GridContainer>
    );
  }
}

const mapStateToProps = ({ messageTemplates }) => ({
  messageTemplates: messageTemplates.data
});


const mapDispatchToProps = dispatch => ({
  messageTemplatesFetchAction: (payload) => dispatch(messageTemplateFetchAction(payload))
});


export default connect(mapStateToProps, mapDispatchToProps)(FillMessages);
