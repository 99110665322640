import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { eventFetchAction, eventFetchError, eventFetchSuccess } from './actions';

import EventService from '../../services/events';
import { tripFetchError } from '../trips/actions';

const service = new EventService();

function* handleFetch(action) {
  try {
    const resp = yield service.getList({
      from: action.payload.params.from,
      to: action.payload.params.to
    });

    let events = [];
    resp.forEach(element => {
      if (element.eventDates && element.eventDates.length > 0) {
        element.eventDates.forEach(eventDate => {
          events.push({
            ...element,
            id: eventDate.id,
            eventDates: [eventDate]
          });
        });
      }
    });
    yield put(eventFetchSuccess(events));
  } catch (err) {
    if (err.response?.data) {
      yield put(eventFetchError(err.response.data));
    } else if (err instanceof Error && err.stack) {
      yield put(tripFetchError(err.stack));
    } else {
      yield put(eventFetchError('An unknown error occured.'));
    }
  }
}

function* watchFetchRequest() {
  yield takeEvery('eventFetchAction', handleFetch);
}

function* eventSaga() {
  yield all([fork(watchFetchRequest)]);
}

export default eventSaga;
