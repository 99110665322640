import React from 'react';
import { connect } from 'react-redux';
import { mediumTypeFetchAction } from '../../../store/medium_types/actions';
import GridContainer from '../../material-ui/Grid/GridContainer';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Button from '../../material-ui/CustomButtons/Button';
import Card from '../../material-ui/Card/Card';
import CardHeader from '../../material-ui/Card/CardHeader';
import GridItem from '../../material-ui/Grid/GridItem';
import Switch from '@material-ui/core/Switch';
import RecipientService from '../../../services/recipients';

class MediumTypeSelect extends React.Component {
  state = {
    isRecipientsImporting: false,
    hasImportingError: false,
    importingError: null,
    recipientService: new RecipientService(),
    importedEmails: null,
    importedPhones: null
  };

  componentDidMount() {
    const { mediumTypes, mediumTypesFetchAction } = this.props;

    if (!mediumTypes || mediumTypes.length === 0)
      mediumTypesFetchAction();
  }

  handleToggleEvents = async (ids) => {
    const { selectedMediumTypes, handleChange } = this.props;
    let newChecked = [...selectedMediumTypes];

    for (const id of ids) {
      const currentIndex = selectedMediumTypes.indexOf(id);

      if (currentIndex === -1) {
        newChecked.push(id);
      } else {
        newChecked = newChecked.filter(x => x !== id);
      }
    }

    await handleChange('mediumTypes', newChecked);
  };

  async handleRecipientImport(event, type) {
    try {
      const { handleChange, recipients } = this.props;
      this.setState({ isRecipientsImporting: true });

      const importedRecipients = await this.state.recipientService.import(event.target.files[0]);

      if (type === 'email') {
        const newImported = importedRecipients.filter(x => !recipients.emails.includes(x));

        handleChange('imported', {
          ...recipients,
          emails: [...newImported, ...recipients.emails]
        });

        this.setState({ importedEmails: newImported.length });
      }

      if (type === 'phone') {
        const newImported = importedRecipients.filter(x => !recipients.phones.includes(x));

        handleChange('imported', {
          ...recipients,
          phones: [...newImported, ...recipients.phones]
        });

        this.setState({ importedPhones: newImported.length });
      }
    } catch (e) {
      console.log(e);

      this.setState({ isRecipientsError: true, importingError: 'Importing error: see console' });
    }

    this.setState({ isRecipientsImporting: false });
  };

  async handlePushCategory(category) {
    const { pushCategories, handleChange } = this.props;

    if (pushCategories.includes(category)) {
      handleChange('pushCategories', pushCategories.filter(x => x !== category));
    } else {
      handleChange('pushCategories', [...pushCategories, category]);
    }
  };

  render() {
    const { classes, notifyType, selectedMediumTypes, mediumTypes, handleChange, prevStep, nextStep, pushCategories } = this.props;
    const { importedEmails, importedPhones, hasImportingError, importingError, isRecipientsImporting } = this.state;

    return (
      <GridContainer className={classes.contentContainer} style={{ maxWidth: '700px' }}>
        <GridItem xs={12} lg={12}>
          <Box display="flex" justifyContent="center">
            <h4 className={classes.titleGray}>Bitte wählen Sie die gewünschten Versandmethoden aus</h4>
          </Box>
        </GridItem>
        {hasImportingError ?
          <GridItem><span style={{ color: '#ff604f' }}>{importingError}</span></GridItem> : ''}
        {mediumTypes.map(mediumType => (
          <GridItem xs={12} lg={12} key={mediumType.name}>
            <Card className={classes.cardWhite}>
              <CardHeader>
                <GridContainer>
                  <GridItem xs={10} sm={10} md={10}>
                    <h4 className={classes.cardTitle}>{mediumType.name}</h4>
                    <p className={classes.cardSubTitle}>
                      {mediumType.Description}
                    </p>
                  </GridItem>
                  <GridItem xs={2} sm={2} md={2}>
                    <Box className={classes.displayFlex + ' ' + classes.contentEnd + ' ' + classes.alignCenter}
                         style={{ height: '100%' }}>
                      <Switch
                        checked={selectedMediumTypes.includes(mediumType.id)}
                        onChange={() => this.handleToggleEvents([mediumType.id])}
                        value="checkedB"
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </Box>
                  </GridItem>
                  {mediumType.id === 1 && notifyType === 3 && (
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer>
                        <GridItem>
                          <Button disabled={isRecipientsImporting}
                                  variant="contained"
                                  color="primary"
                                  component="label"
                                  className={classes.buttonSlim}>
                            Datei auswählen
                            <input
                              type="file"
                              style={{ display: 'none' }}
                              onChange={event => {
                                this.handleRecipientImport(event, 'email');
                                event.target.value = '';
                              }}
                            />
                          </Button>
                        </GridItem>
                        <GridItem>
                          <p style={{ margin: '7px 0' }}>Newsletter-Liste
                            hochladen {importedEmails !== null ? `(+${importedEmails} new recipients loaded)` : ''}</p>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  )}
                  {mediumType.id === 2 && notifyType === 3 && (
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer>
                        <GridItem>
                          <Button disabled={isRecipientsImporting}
                                  variant="contained"
                                  color="primary"
                                  component="label"
                                  className={classes.buttonSlim}>
                            Datei auswählen
                            <input
                              type="file"
                              style={{ display: 'none' }}
                              onChange={event => {
                                this.handleRecipientImport(event, 'phone');
                                event.target.value = '';
                              }}
                            />
                          </Button>
                        </GridItem>
                        <GridItem>
                          <p style={{ margin: '7px 0' }}>SMS Liste
                            hochladen {importedPhones !== null ? `(+${importedPhones} new recipients loaded)` : ''}</p>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  )}
                  {notifyType === 3 && mediumType.id === 3 && (
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer>
                        <GridItem>
                          <Switch
                            checked={pushCategories.includes('trips')}
                            onChange={() => this.handlePushCategory('trips')}
                            value="checkedB"
                            color="primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                          <span style={{ margin: '7px 0' }}>Fahrten</span>
                        </GridItem>
                        <GridItem>
                          <Switch
                            checked={pushCategories.includes('events')}
                            onChange={() => this.handlePushCategory('events')}
                            value="checkedB"
                            color="primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                          <span style={{ margin: '7px 0' }}>Veranstaltungen</span>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  )}
                </GridContainer>
              </CardHeader>
            </Card>
          </GridItem>
        ))}

        <Box display="flex" justifyContent="flex-end" alignItems="center" width="100%">
          <Link className={classes.link} onClick={async () => {
            await handleChange('messages', []);
            await handleChange('mediumTypes', []);

            prevStep();
          }} style={{ marginRight: '20px' }}>Zurück</Link>
          {selectedMediumTypes.length > 0 &&
          <Button color="primary" onClick={nextStep} style={{ marginRight: '15px' }}>
            Auswahl übernehmen
          </Button>
          }
        </Box>
      </GridContainer>
    );
  }
}

const mapStateToProps = ({ mediumTypes }) => ({
  mediumTypes: mediumTypes.data
});


const mapDispatchToProps = dispatch => ({
  mediumTypesFetchAction: (payload) => dispatch(mediumTypeFetchAction(payload))
});


export default connect(mapStateToProps, mapDispatchToProps)(MediumTypeSelect);
