import axiosStatic from 'axios';
import { API_URL } from '../configs/server';

export function axios(configs, resolve, reject) {
  const req = axiosStatic(configs);

  return req
    .then(res => {
      resolve(res.data);
    })
    .catch(err => {
      reject(err);
    });
}

export function getConfigs(method, contentType, endpointPath, options) {
  const url = API_URL + endpointPath;
  const configs = {...options, method, url};

  const account = JSON.parse(sessionStorage.getItem('account'));

  configs.headers = {
    ...options.headers
  };

  if (account)
    configs.headers = {
      ...configs.headers,
      'Authorization': `Bearer ${account.token}`
    };

  return configs;
}