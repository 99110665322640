export const messageTemplateFetchAction = (payload) => {
  return {
    type: 'messageTemplateFetchAction',
    payload
  };
};

export const messageTemplateFetchSuccess = (payload) => {
  return {
    type: 'messageTemplateFetchSuccess',
    payload
  };
};

export const messageTemplateFetchError = (payload) => {
  return {
    type: 'messageTemplateFetchError',
    payload
  };
};