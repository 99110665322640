export const initialState = {
  data: [],
  currentId: undefined,
  errors: undefined,
  loading: false
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case 'mediumTypeFetch': {
      return { ...state, loading: true };
    }
    case 'mediumTypeSelectAction': {
      return { ...state, currentId: action.payload };
    }
    case 'mediumTypeFetchSuccess': {
      return { ...state, loading: false, data: action.payload };
    }
    case 'mediumTypeFetchError': {
      return { ...state, loading: true, errors: action.payload };
    }
    default: {
      return state;
    }
  }
}

export { reducer as mediumTypeReducer };
