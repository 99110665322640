import React from 'react';
import { connect } from 'react-redux';
import BugReport from '@material-ui/icons/BugReport';
import Table from '../material-ui/Table/Table';
import Code from '@material-ui/icons/Code';
import Cloud from '@material-ui/icons/Cloud';
import CustomTabs from '../material-ui/CustomTabs/CustomTabs';
import GridContainer from '../material-ui/Grid/GridContainer';
import Button from '../material-ui/CustomButtons/Button';
import CardFooter from '../material-ui/Card/CardFooter';
import { NavLink } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { mediumTypeFetchAction } from '../../store/medium_types/actions';
import { messageDeleteAction } from '../../store/messages/actions';
import { statusSeite } from '../../models/mediumTypeEnum';

class MediumMessageTable extends React.Component {
  componentDidMount() {
  }

  deleteMessage = (messageId) => {
    const { messages, messageDeleteAction } = this.props;
    const messageIndex = messages.indexOf(messages.find(message => message.id === messageId));

    if (messageIndex !== -1) messages.splice(messageIndex, 1);

    messageDeleteAction({
      id: messageId
    });
  };

  render() {
    const { messages, mediumTypes, loading, classes, footer, handleMediumTypeChange, rowsLimit } = this.props;

    return (
      <GridContainer>
        {loading && messages.length === 0 && (
          <span>Loading...</span>
        )}

        <CustomTabs
          title="Verschickte Nachrichten"
          subtitle="Übersicht der letzten Nachrichten"
          headerColor="primary"
          classes={classes}
          onChange={handleMediumTypeChange}
          tabs={mediumTypes.map(mediumType => {
            const typeMessages = messages.filter(x => x.medium && x.medium.id === mediumType.id).slice(0, rowsLimit);
            const isShortMessages = typeMessages.every(x => !x.Title);
            const tableColumns = ['Text', 'Datum', 'Gesendet'];

            if (!isShortMessages) tableColumns.unshift('Titel');

            if (mediumType.id === statusSeite)
              tableColumns.push('Aktionen');

            return ({
              tabKey: mediumType.id,
              tabName: mediumType.name,
              tabMeta: {
                icon: mediumType.Icon
              },
              tabContent: (
                <Table
                  tableHeaderColor="warning"
                  tableHead={tableColumns}
                  tableData={typeMessages.map(message => {
                    const data = [
                      message.Text,
                      new Date(message.created_at).toLocaleDateString(),
                      message.Receivers
                    ];

                    if (!isShortMessages) data.unshift(message.Title);

                    if (message.medium.id === statusSeite)
                      data.push(<Button color="primary" className={classes.buttonSlim}
                        onClick={() => this.deleteMessage(message.id)}>Löschen</Button>);

                    return data;
                  })}
                />
              )
            });
          })}
          footer={footer}
        />
      </GridContainer>
    );
  }
}

const mapStateToProps = ({ mediumTypes, messages }) => ({
  messages: messages.data
});


const mapDispatchToProps = dispatch => ({
  messageDeleteAction: (payload) => dispatch(messageDeleteAction(payload))
});


export default connect(mapStateToProps, mapDispatchToProps)(MediumMessageTable);
