import React from 'react';
import DashboardCard from '../../dashboard/DashboardCard';
import GridItem from '../../material-ui/Grid/GridItem';
import GridContainer from '../../material-ui/Grid/GridContainer';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Button from '../../material-ui/CustomButtons/Button';
import MediumService from '../../../services/mediums';
import MessageService from '../../../services/messages';
import NotificationService from '../../../services/notifications';
import RecipientService from '../../../services/recipients';

class ShowMessageMetrics extends React.Component {
  state = {
    mediumService: new MediumService(),
    messageService: new MessageService(),
    notificationService: new NotificationService(),
    recipientService: new RecipientService(),

    metrics: [],
    isRecipientsImporting: false,
    isSending: false
  };

  async componentDidMount() {
    const { selectedMediumTypes, events, trips, notifyType, pushCategories, handleChange } = this.props;

    try {
      const metrics = await this.state.notificationService.getMetrics({
        mediumTypeIds: selectedMediumTypes,
        eventIds: events.map(event => event.id),
        tripIds: trips.map(trip => trip.id),
        notifyTypeId: notifyType,
        pushCategories: pushCategories
      });

      this.setState({ metrics });
    } catch (e) {
      handleChange('errors', [e]);
    }
  }

  getMetricCard(metric) {
    const { classes, recipients } = this.props;

    const getImportedCount = (mediumType) => {
      switch (mediumType) {
        case 1:
          return recipients.emails;
        case 2:
          return recipients.phones;
        default:
          return 0;
      }
    };

    return (
      <GridItem xs={12} sm={6} md={4} key={metric.medium.id}>
        <DashboardCard color={metric.medium.color} icon={metric.medium.Icon}
          name={`${metric.medium.name} / ${metric.tarif}€`}
          value={metric.recipients + (getImportedCount(metric.medium.id).length || 0)} classes={classes}>
        </DashboardCard>
      </GridItem>
    );
  }

  async sendMessages(callback) {
    this.setState({ isSending: true });
    const { notifyType, messages, trips, events, dateFrom, recipients, pushCategories, handleChange, selectedMediumTypes } = this.props;
    const { notificationService } = this.state;

    const tripIds = trips.map(trip => trip.id);
    const eventIds = events.map(event => event.id);

    const errors = [];

    try {
      if (selectedMediumTypes.find(x => x === 1)) {
        await notificationService.sendEmail({
          message: messages.find(x => x.mediumType === 1),
          tripIds: tripIds,
          eventIds: eventIds,
          emails: recipients.emails,
          notifyType,
          date: dateFrom
        });
      }
    } catch (e) {
      const messageErrors = e.response.data.message.error;
      const receiversCount = e.response.data.message.receivers || 0;

      if (messageErrors && messageErrors.length > 0) {
        const innerErrors = [];

        messageErrors.forEach(e => {
          innerErrors.push(e);
        });

        errors.push({
          main: `Not all emails were sent (${receiversCount} sent, ${receiversCount + messageErrors.length} in all)`,
          innerErrors
        });
      }
    }

    try {
      if (selectedMediumTypes.find(x => x === 2)) {
        await notificationService.sendSms({
          message: messages.find(x => x.mediumType === 2),
          tripIds: tripIds,
          eventIds: eventIds,
          notifyType,
          phones: recipients.phones,
          date: dateFrom
        });
      }
    } catch (err) {
      const messageErrors = err.response.data.message.error;
      const receiversCount = err.response.data.message.receivers || 0;

      if (messageErrors && messageErrors.length > 0) {
        const innerErrors = [];

        messageErrors.forEach(e => {
          innerErrors.push(e);
        });

        errors.push({
          main: `Not all sms were sent (${receiversCount} sent, ${receiversCount + messageErrors.length} in all)`,
          innerErrors
        });
      }
    }

    try {
      if (selectedMediumTypes.find(x => x === 3)) {
        await notificationService.sendPush({
          message: messages.find(x => x.mediumType === 3),
          tripIds: tripIds,
          eventIds: eventIds,
          notifyType,
          date: dateFrom,
          categories: pushCategories
        });
      }
    } catch (err) {
      const messageErrors = err.response.data.message.error;
      const receiversCount = err.response.data.message.receivers || 0;

      if (messageErrors && messageErrors.length > 0) {
        const innerErrors = [];

        messageErrors.forEach(e => {
          innerErrors.push(e);
        });

        errors.push({
          main: `Not all push were sent (${receiversCount} sent, ${receiversCount + messageErrors.length} in all)`,
          innerErrors
        });
      }
    }

    if (selectedMediumTypes.find(x => x === 4))
      await notificationService.sendStatusSeite({
        message: messages.find(x => x.mediumType === 4),
        notifyType,
        date: dateFrom
      });

    callback();
    handleChange('errors', errors);
  }

  render() {
    const { classes, prevStep, nextStep } = this.props;
    const { metrics, isSending } = this.state;

    return (
      <GridContainer className={classes.contentContainer} style={{ maxWidth: '1000px' }}>
        {metrics.filter(metric => metric.medium.useInStats).map(metric => this.getMetricCard(metric))}
        <GridItem lg={12} sm={12} md={12}>
          <Box display="flex" justifyContent="center">
            <Link className={classes.link} onClick={prevStep}
              style={{ marginRight: '20px', padding: '12px' }}>Zurück</Link>
            <Button color="primary" disabled={isSending} onClick={() => this.sendMessages(nextStep)}>
              Nachrichten jetzt senden
            </Button>
          </Box>
        </GridItem>
      </GridContainer>
    );
  }
}

export default ShowMessageMetrics;
