import React, { useEffect } from 'react';
import { useAuth } from 'contexts/authContext';
import useForm from 'react-hook-form';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CustomInput from '../../components/material-ui/CustomInput/CustomInput';
import Button from '../../components/material-ui/CustomButtons/Button';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

const styles = {
  loginForm: {
    backgroundColor: 'white',
    zIndex: 2,
    borderRadius: '5px',
    padding: '2em'
  },
  header: {
    fontSize: '22px',
    lineHeight: 1.5,
    margin: '0.25em 0',
    textAlign: 'center',
    fontWeight: 'bold'
  },
  formSubmit: {
    marginTop: '1em',
    textAlign: 'right'
  }
};

const useStyles = makeStyles(styles);

const Login = ({ history }) => {
  const classes = useStyles();
  const { login, errors } = useAuth();
  const { register, handleSubmit, setValue } = useForm({
    reValidateMode: 'onBlur'
  });

  useEffect(() => {
    register({ name: 'username' });
    register({ name: 'password' });
  }, [register]);

  const handleChangeInput = event => {
    setValue(event.target.id, event.target.value);
  };

  const handleSubmitForm = async values => {
    if (values.username && values.password) {
      await login(values.username, values.password);
      history.push('/');
    }
  };

  return (
    <form
      className={classes.loginForm}
      onSubmit={handleSubmit(handleSubmitForm)}
    >
      <h1 className={classes.header}>Anmeldung</h1>
      {errors && errors.length > 0 && (
        <Alert severity="error" className={classes.errorAlert}>
          <AlertTitle>Error</AlertTitle>
          {errors.map((error) =>
            (<p>{error}</p>)
          )}
        </Alert>
      )}
      <CustomInput
        labelText="Benutzername"
        id="username"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          onChange: handleChangeInput
        }}
      />
      <CustomInput
        labelText="Passwort"
        id="password"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          onChange: handleChangeInput,
          type: 'password'
        }}
      />
      <div className={classes.formSubmit}>
        <Button color="primary" type="submit">
          Anmelden
        </Button>
      </div>
    </form>
  );
};

Login.propTypes = {
  history: PropTypes.any
};

export default withRouter(Login);
