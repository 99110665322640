import React, { useRef } from 'react';
import CardHeader from '../material-ui/Card/CardHeader';
import Card from '../material-ui/Card/Card';
import GridItem from '../material-ui/Grid/GridItem';

import { CardContent, FormGroup } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

class MediumTypeMessageTemplateCard extends React.Component {
  state = {
    template: undefined,
    message: undefined
  };

  componentDidMount() {
    const { templates, message } = this.props;

    this.setState({
      template: templates[0],
      message: message
    })
  }

  handleInput = async (field, value) => {
    const { message } = this.state;
    const { handleMessage } = this.props;

    this.setState({
      message: {
        ...message,
        [field]: value
      }
    });

    await handleMessage({
      ...message,
      [field]: value
    });
  };

  render() {
    const { classes, message, mediumType } = this.props;
    const { template } = this.state;

    return (
      <GridItem xs={12} sm={12} md={12}>
        {message && template &&
          <Card className={classes.cardWhite}>
            <CardHeader>
              <h4 className={classes.cardTitle}>{template.medium.name}</h4>
            </CardHeader>
            <CardContent>
              {mediumType !== 2 &&
                <FormGroup style={{ marginBottom: '25px' }}>
                  <TextField id="standard-basic" label="Titel" defaultValue={message.title}
                    onChange={(e) => this.handleInput('title', e.currentTarget.value)} />
                </FormGroup>
              }
              <FormGroup>
                <TextField
                  id="outlined-multiline-flexible"
                  label="Die Nachricht"
                  multiline
                  rowsMax="30"
                  defaultValue={message.text}
                  onChange={(e) => this.handleInput('text', e.currentTarget.value)}
                />
              </FormGroup>
            </CardContent>
          </Card>
        }
      </GridItem>
    );
  }
}

export default MediumTypeMessageTemplateCard;
