import {
  defaultFont,
  primaryColor,
  dangerColor,
  grayColor
} from "../../../../assets/jss/material-dashboard-react.js";
import tooltipStyle from "../../../../assets/jss/material-dashboard-react/tooltipStyle.js";
import checkboxAdnRadioStyle from "../../../../assets/jss/material-dashboard-react/checkboxAdnRadioStyle.js";
import dashboardStyle from '../views/dashboardStyle';
import flexStyle from './flexStyle';
import customInputStyle from './customInputStyle';

const tasksStyle = {
  ...tooltipStyle,
  ...checkboxAdnRadioStyle,
  ...dashboardStyle,
  ...flexStyle,
  ...customInputStyle,
  table: {
    marginBottom: "0",
    overflow: "visible",
    minHeight: "130px"
  },
  tableRow: {
    position: "relative",
    borderBottom: "1px solid " + grayColor[5]
  },
  tableActions: {
    display: "flex",
    border: "none",
    padding: "12px 8px !important",
    verticalAlign: "middle"
  },
  tableCell: {
    ...defaultFont,
    padding: "8px",
    verticalAlign: "middle",
    border: "none",
    lineHeight: "1.42857143",
    fontSize: "14px"
  },
  tableCellRTL: {
    textAlign: "right"
  },
  tableActionButton: {
    width: "27px",
    height: "27px",
    padding: "0"
  },
  tableActionButtonIcon: {
    width: "17px",
    height: "17px"
  },
  edit: {
    backgroundColor: "transparent",
    color: primaryColor[0],
    boxShadow: "none"
  },
  close: {
    backgroundColor: "transparent",
    color: dangerColor[0],
    boxShadow: "none"
  },
  loading: {
    width: '100%',
    minHeight: '130px',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
};
export default tasksStyle;