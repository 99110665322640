export const mediumFetchAction = (payload) => {
  return {
    type: 'mediumFetchAction',
    payload
  };
};

export const mediumFetchSuccess = (payload) => {
  return {
    type: 'mediumFetchSuccess',
    payload
  };
};

export const mediumFetchError = (payload) => {
  return {
    type: 'mediumFetchError',
    payload
  };
};


export const mediumMetricsFetchAction = (payload) => {
  return {
    type: 'mediumMetricsFetchAction',
    payload
  };
};
