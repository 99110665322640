import { combineReducers } from 'redux';
import { all, fork } from 'redux-saga/effects';
import { connectRouter } from 'connected-react-router';
import { messageReducer } from './messages/reducer';
import { mediumTypeReducer } from './medium_types/reducer';
import { eventReducer } from './events/reducer';
import { notificationTypeReducer } from './notification_types/reducer';
import { messageTemplateReducer } from './message_templates/reducer';
import { mediumReducer } from './mediums/reducer';
import { tripReducer } from './trips/reducer';

import messagesSaga from './messages/saga';
import mediumTypeSaga from './medium_types/saga';
import typeSaga from './notification_types/saga';
import eventSaga from './events/saga';
import messageTemplateSaga from './message_templates/saga';
import mediumSaga from './mediums/saga';
import tripSaga from './trips/saga';

export const createRootReducer = (history) =>
  combineReducers({
    messages: messageReducer,
    mediumTypes: mediumTypeReducer,
    types: notificationTypeReducer,
    events: eventReducer,
    trips: tripReducer,
    messageTemplates: messageTemplateReducer,
    mediums: mediumReducer,
    router: connectRouter(history)
  });

export function* rootSaga() {
  yield all([fork(messagesSaga),
    fork(mediumTypeSaga),
    fork(mediumSaga),
    fork(typeSaga),
    fork(eventSaga),
    fork(tripSaga),
    fork(messageTemplateSaga)]);
}
