export const messageFetchAction = (payload) => {
  return {
    type: 'messageFetchAction',
    payload
  };
};

export const messageCreateAction = (payload) => {
  return {
    type: 'messageCreateAction',
    payload
  };
};

export const messageDeleteAction = (payload) => {
  return {
    type: 'messageDeleteAction',
    payload
  };
};

export const messageCreateSuccess = (payload) => {
  return {
    type: 'messageCreateSuccess',
    payload
  };
};

export const messageDeleteSuccess = (payload) => {
  return {
    type: 'messageDeleteSuccess',
    payload
  };
};

export const messageFetchSuccess = (payload) => {
  return {
    type: 'messageFetchSuccess',
    payload
  };
};

export const messageFetchPagedSuccess = (payload) => {
  return {
    type: 'messageFetchPagedSuccess',
    payload
  };
};

export const messageActionError = (payload) => {
  return {
    type: 'messageActionError',
    payload
  };
};
