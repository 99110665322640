import React from 'react';
import { connect } from 'react-redux';
import SelectNotifyType from '../../components/notifications/steps/SelectNotifyType';
import SelectMediumTypes from '../../components/notifications/steps/SelectMediumTypes';
import SelectEvents from '../../components/notifications/steps/SelectEvents';
import FillMessages from '../../components/notifications/steps/FillMessages';
import ShowMessageMetrics from '../../components/notifications/steps/ShowMessageMetrics';
import ShowResults from '../../components/notifications/steps/ShowResults';
import NotificationStepper from '../../components/notifications/NotificationStepper';
import Grid from '@material-ui/core/Grid';
import SelectTrips from '../../components/notifications/steps/SelectTrips';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import axios from 'axios';

class Notifications extends React.Component {
  state = {
    step: 0,
    notifyTypeId: 1,
    events: [],
    trips: [],
    mediumTypes: [],
    messages: [],
    dateFrom: new Date(),
    dateTo: new Date(),
    imported: {
      emails: [],
      phones: []
    },
    pushCategories: [],
    errors: []
  };

  componentDidMount() {
    const notificationCache = sessionStorage.getItem('notificationCache');

    if (notificationCache) {
      const stateCached = JSON.parse(notificationCache);

      this.setState(stateCached);
    }

    axios.interceptors.request.use((config) => {
      this.handleFormChange('errors', []);
      return config;
    });
  }

  cacheState = () => {
    sessionStorage.setItem('notificationCache', JSON.stringify(this.state));
  };

  nextStep = () => {
    this.setState({
      step: this.specifyStep(true)
    }, this.cacheState);
  };

  prevStep = () => {
    this.setState({
      step: this.specifyStep(false)
    }, this.cacheState);
  };

  specifyStep = (next) => {
    const { step, notifyTypeId } = this.state;

    if (next && notifyTypeId === 3 && step === 0) {
      return step + 2;
    }

    if (!next && notifyTypeId === 3 && step === 2) {
      return step - 2;
    }

    return next ? step + 1 : step - 1;
  };

  handleFormChange = async (key, value) => {
    return new Promise((resolve => {
      this.setState({
        [key]: value
      }, () => {
        this.cacheState();
        resolve();
      });
    }));
  };

  renderStep = () => {
    const { step, notifyTypeId, events, trips, imported, mediumTypes, messages, dateFrom, dateTo, pushCategories } = this.state;
    const { classes } = this.props;

    switch (step) {
      case 0:
        return <SelectNotifyType classes={classes}
                                 handleChange={this.handleFormChange}
                                 nextStep={this.nextStep}/>;
      case 1:
        return (<Grid>
          {notifyTypeId === 1 &&
          <SelectTrips classes={classes}
                       dateFrom={dateFrom}
                       dateTo={dateTo}
                       selectedTrips={trips}
                       handleChange={this.handleFormChange}
                       prevStep={this.prevStep}
                       nextStep={this.nextStep}/>}
          {notifyTypeId === 2 &&
          <SelectEvents classes={classes}
                        dateFrom={dateFrom}
                        dateTo={dateTo}
                        selectedEvents={events}
                        handleChange={this.handleFormChange}
                        prevStep={this.prevStep}
                        nextStep={this.nextStep}/>}
        </Grid>);
      case 2:
        return <SelectMediumTypes classes={classes}
                                  notifyType={notifyTypeId}
                                  selectedMediumTypes={mediumTypes}
                                  recipients={imported}
                                  pushCategories={pushCategories}
                                  handleChange={this.handleFormChange}
                                  prevStep={this.prevStep}
                                  nextStep={this.nextStep}/>;
      case 3:
        return <FillMessages classes={classes}
                             dateFrom={dateFrom}
                             dateTo={dateTo}
                             selectedMediumTypes={mediumTypes}
                             notifyType={notifyTypeId}
                             selectedEvents={events}
                             selectedTrips={trips}
                             messages={messages}
                             handleChange={this.handleFormChange}
                             prevStep={this.prevStep}
                             nextStep={this.nextStep}/>;
      case 4:
        return <ShowMessageMetrics classes={classes}
                                   events={events}
                                   trips={trips}
                                   dateFrom={dateFrom}
                                   dateTo={dateTo}
                                   messages={messages}
                                   recipients={imported}
                                   notifyType={notifyTypeId}
                                   pushCategories={pushCategories}
                                   selectedMediumTypes={mediumTypes}
                                   handleChange={this.handleFormChange}
                                   prevStep={this.prevStep}
                                   nextStep={this.nextStep}/>;
      case 5:
        return <ShowResults classes={classes} prevStep={this.prevStep}/>;
      default:
        throw new Error('not implemented');
    }
  };

  render() {
    const { classes } = this.props;
    const { step, errors } = this.state;

    return (
      <div>
        <NotificationStepper activeStep={step}/>
        {errors && errors.length > 0 && (
          <Alert severity="error" className={classes.errorAlert}>
            <AlertTitle>Error</AlertTitle>
            {errors.map((error) =>
              (
                <p>
                  <span>{error.main}</span>
                  {error.innerErrors && error.innerErrors.length > 0 && (
                    <ul>
                      {error.innerErrors.map(ie => (
                        <li>{ie}</li>
                      ))}
                    </ul>
                  )}
                </p>
              )
            )}
          </Alert>
        )}
        {this.renderStep()}
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
