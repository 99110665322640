export const initialState = {
  data: [],
  errors: undefined,
  limitOnPage: 5,
  page: 1,
  loading: false
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case 'messageFetch': {
      return { ...state, loading: true };
    }
    case 'messageFetchSuccess': {
      return { ...state, loading: false, data: action.payload, page: 1 };
    }
    case 'messageFetchPagedSuccess': {
      return {
        ...state, loading: false, data: [...state.data, ...action.payload],
        page: state.page + 1
      };
    }
    case 'messageCreate': {
      return { ...state, loading: true, data: state.data.push(action.payload) };
    }
    case 'messageDeleteSuccess': {
      return {
        ...state,
        loading: false,
        data: state.data.filter(message => message.id !== action.payload.id)
      };
    }
    case 'messageCreateSuccess': {
      return { ...state, loading: false, created: action.payload };
    }
    case 'messageActionError': {
      return { ...state, loading: true, errors: action.payload };
    }
    default: {
      return state;
    }
  }
}

export { reducer as messageReducer };
