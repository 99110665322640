import { axios, getConfigs } from './common';

export default class MessageService {
  async getMessages(params) {
    return new Promise(async (resolve, reject) => {
      let url = `/messages`;

      const options = {};
      const configs = getConfigs('get', 'application/json', url, options);

      configs.params = {
        ...params,
        _sort: 'created_at:DESC'
      };

      axios(configs, resolve, reject);
    });
  }

  async getCount(params) {
    return new Promise(async (resolve, reject) => {
      let url = `/messages/count`;

      const options = {};
      const configs = getConfigs('get', 'application/json', url, options);

      configs.params = params;

      axios(configs, resolve, reject);
    });
  }

  async create(message) {
    const formData = new FormData();
    formData.append(...message);

    return new Promise(async (resolve, reject) => {
      let url = `/messages`;

      const options = {};
      const configs = getConfigs('post', 'application/json', url, options);

      configs.data = formData;

      axios(configs, resolve, reject);
    });
  }

  async delete(id) {
    return new Promise(async (resolve, reject) => {
      let url = `/messages/${id}`;

      const options = {};
      const configs = getConfigs('delete', 'application/json', url, options);

      axios(configs, resolve, reject);
    });
  }
}
