import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { mediumTypeFetchAction, mediumTypeFetchError, mediumTypeFetchSuccess } from './actions';
import MediumTypeService from '../../services/mediumTypes';

const service = new MediumTypeService();

function* handleFetch(action) {
  try {
    const data = yield service.getList(action.params);

    yield put(mediumTypeFetchSuccess(data));
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(mediumTypeFetchError(err.stack));
    } else {
      yield put(mediumTypeFetchError('An unknown error occured.'));
    }
  }
}

function* watchFetchRequest() {
  yield takeEvery('mediumTypeFetchAction', handleFetch);
}

function* mediumTypeSaga() {
  yield all([fork(watchFetchRequest)]);
}

export default mediumTypeSaga;
