import { axios, getConfigs } from './common';

export default class NotificationService {
  async getMetrics(params) {
    return new Promise(async (resolve, reject) => {
      let url = `/metrics`;

      const options = {};
      const configs = getConfigs('get', 'application/json', url, options);

      configs.params = params;

      axios(configs, resolve, reject);
    });
  }

  async sendEmail(params) {
    return new Promise(async (resolve, reject) => {
      let url = `/mail`;

      const options = {};
      const configs = getConfigs('post', 'application/json', url, options);

      const formData = new FormData();

      formData.append("title", params.message.title);
      formData.append("text", params.message.text);
      formData.append("tripIds", params.tripIds);
      formData.append("eventIds", params.eventIds);
      formData.append("emails", params.emails);
      formData.append("date", params.date);
      formData.append("notifyType", params.notifyType);

      configs.data = formData;

      axios(configs, resolve, reject);
    });
  }

  async sendSms(params) {
    return new Promise(async (resolve, reject) => {
      let url = `/sms`;

      const options = {};
      const configs = getConfigs('post', 'application/json', url, options);

      const formData = new FormData();

      formData.append("text", params.message.text);
      formData.append("tripIds", params.tripIds);
      formData.append("eventIds", params.eventIds);
      formData.append("date", params.date);
      formData.append("phones", params.phones);
      formData.append("notifyType", params.notifyType);

      configs.data = formData;

      axios(configs, resolve, reject);
    });
  }

  async sendPush(params) {
    return new Promise(async (resolve, reject) => {
      let url = `/push`;

      const options = {};
      const configs = getConfigs('post', 'application/json', url, options);

      const formData = new FormData();

      formData.append("title", params.message.title);
      formData.append("text", params.message.text);
      formData.append("tripIds", params.tripIds);
      formData.append("date", params.date);
      formData.append("eventIds", params.eventIds);
      formData.append("categories", params.categories);
      formData.append("notifyType", params.notifyType);

      configs.data = formData;

      axios(configs, resolve, reject);
    });
  }

  async sendStatusSeite(params) {
    return new Promise(async (resolve, reject) => {
      let url = `/status-seite`;

      const options = {};
      const configs = getConfigs('post', 'application/json', url, options);

      const formData = new FormData();

      formData.append("title", params.message.title);
      formData.append("text", params.message.text);
      formData.append("date", params.date);
      formData.append("notifyType", params.notifyType);

      configs.data = formData;

      axios(configs, resolve, reject);
    });
  }
}
