import React from 'react';
import { connect } from 'react-redux';
import GridContainer from '../../material-ui/Grid/GridContainer';
import GridItem from '../../material-ui/Grid/GridItem';
import { NavLink } from 'react-router-dom';

class ShowResults extends React.Component {
  componentDidMount() {

  }

  render() {
    const { classes } = this.props;

    return (
      <GridContainer>
        <GridItem style={{ margin: '0 auto', textAlign: 'center' }}>
          <h2>Ihre Nachricht wurde erfolgreich versendet.</h2>
          <NavLink className={classes.link} to={'/admin/dashboard'}
                   onClick={() => sessionStorage.removeItem('notificationCache')}>Zurück zum Dashboard</NavLink>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = () => ({});


const mapDispatchToProps = dispatch => ({});


export default connect(mapStateToProps, mapDispatchToProps)(ShowResults);
