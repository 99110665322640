import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { tripFetchAction, tripFetchError, tripFetchSuccess } from './actions';
import TripService from '../../services/trips';
import { eventFetchError } from '../events/actions';

const service = new TripService();

function* handleFetch(action) {
  try {
    const resp = yield service.getList({
      fromDate: action.payload.params.fromDate.toISOString(),
      toDate: action.payload.params.toDate.toISOString()
    });

    yield put(tripFetchSuccess(resp));
  } catch (err) {
    if (err.response?.data) {
      yield put(eventFetchError(err.response.data))
    } else if (err instanceof Error && err.stack) {
      yield put(tripFetchError(err.stack));
    } else {
      yield put(tripFetchError('An unknown error occured.'));
    }
  }
}

function* watchFetchRequest() {
  yield takeEvery('tripFetchAction', handleFetch);
}

function* tripSaga() {
  yield all([fork(watchFetchRequest)]);
}

export default tripSaga;
