import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'transparent',
    width: '1000px',
    margin: '0 auto',
    marginBottom: '50px'
  }
}));

function getSteps() {
  return [
    'Benachrichtigungs Typ',
    'Auswahl',
    'Medium',
    'Inhalt',
    'Fertig'
  ];
}

export default function NotificationStepper(props) {
  const classes = useStyles();
  const { activeStep } = props;

  const steps = getSteps();

  return (
    <Stepper activeStep={activeStep} alternativeLabel className={classes.root}>
      {steps.map(label => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
