import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { mediumFetchAction, mediumFetchError, mediumFetchSuccess } from './actions';
import MediumService from '../../services/mediums';
import SubscriptionService from '../../services/subscriptions';

const service = new MediumService();
const subsService = new SubscriptionService();

function* handleFetch(payload) {
  try {
    const data = yield service.getList(payload.payload);

    yield put(mediumFetchSuccess(data));
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(mediumFetchError(err.stack));
    } else {
      yield put(mediumFetchError('An unknown error occured.'));
    }
  }
}

function* handleMetricsFetch(payload) {
  try {
    const metrics = [];

    const mediums = yield service.getList(payload.payload.params);

    const emails = [
      'test@test.com',
      'test1@test.com',
      'test2@test.com'
    ];

    const phones = [
      '+474732742743',
      '+432545465477',
      '+867876970980',
      '+765765754543'
    ];

    for (const medium of mediums) {
      let count = 0;

      switch (medium.medium.id) {
        case 1: // Email
          count = emails.length;
          break;
        case 2: // SMS
          count = phones.length;
          break;
        case 3: //Push
          count = yield subsService.getCount();
          break;
      }

      metrics.push({
        mediumType: medium.medium,
        count: count,
        tariff: medium.tarif
      });
    }

    console.log(metrics);

    yield put(mediumFetchSuccess(metrics));
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(mediumFetchError(err.stack));
    } else {
      yield put(mediumFetchError('An unknown error occured.'));
    }
  }
}

function* watchFetchRequest() {
  yield takeEvery('mediumFetchAction', handleFetch);
}

function* watchMetricsFetchRequest() {
  yield takeEvery('mediumMetricsFetchAction', handleMetricsFetch);
}

function* mediumSaga() {
  yield all([fork(watchFetchRequest),
    fork(watchMetricsFetchRequest)]);
}

export default mediumSaga;