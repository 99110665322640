import {all, fork, put, takeEvery} from "redux-saga/effects";

import { messageTemplateFetchError, messageTemplateFetchSuccess } from './actions';
import MessageTemplateService from '../../services/messageTemplates';

const service = new MessageTemplateService();

function* handleFetch(payload) {
  try {
    yield put(messageTemplateFetchSuccess(yield service.getList(payload.payload.params)))
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(messageTemplateFetchError(err.stack))
    } else {
      yield put(messageTemplateFetchError('An unknown error occured.'))
    }
  }
}

function* watchFetchRequest() {
  yield takeEvery('messageTemplateFetchAction', handleFetch)
}

function* messageTemplateSaga() {
  yield all([fork(watchFetchRequest)])
}

export default messageTemplateSaga;