import CardHeader from '../material-ui/Card/CardHeader';
import CardIcon from '../material-ui/Card/CardIcon';
import Icon from '@material-ui/core/Icon';
import CardFooter from '../material-ui/Card/CardFooter';
import Card from '../material-ui/Card/Card';
import React from 'react';

export default function DashboardCard(props) {
  const { classes, color, value, unit, footer, icon, name, ...rest } = props;
  
  return (<Card>
    <CardHeader color={color} stats icon>
      <CardIcon color={color}>
        <Icon>{icon}</Icon>
      </CardIcon>
      <p className={classes.cardCategory}>{name}</p>
      <h3 className={classes.dashboardCardTitle}>
        {value} <small>{unit}</small>
      </h3>
    </CardHeader>
    <CardFooter stats>
      <div className={classes.stats}>
        {footer}
      </div>
    </CardFooter>
  </Card>);
}