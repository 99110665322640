import React from 'react';
import Login from '../views/Login/Login';
import { makeStyles } from '@material-ui/core/styles';
import bgImg from '../assets/img/navi_background.jpg';

const styles = {
  loginPage: {
    backgroundImage: `url(${bgImg})`,
    height: '100%',
    width: '100%',
    minHeight: '100vh',
    minWidth: '100vw',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    '&::before': {
      content: '" "',
      display: 'block',
      position: 'absolute',
      left: 0,
      top: 0,
      height: '100%',
      width: '100%',
      backgroundColor: 'rgba(0,0,0,0.3)'
    }
  }
};

const useStyles = makeStyles(styles);

const LoginLayout = () => {
  const classes = useStyles();

  return (
    <div className={classes.loginPage}>
      <Login />
    </div>
  );
};

export default LoginLayout;
